function loadLogin() {
  let e = $("#m_login");
  $("#m_login_forget_password").click(function (i) {
    i.preventDefault(), e.removeClass("m-login--signin"), e.removeClass("m-login--signup"), e.addClass("m-login--forget-password"), mUtil.animateClass(e.find(".m-login__forget-password")[0], "flipInX animated")
  });
  $("#m_login_forget_password_cancel").click(function (i) {
    i.preventDefault();
    e.removeClass("m-login--forget-password"), e.removeClass("m-login--signup"), e.addClass("m-login--signin"), mUtil.animateClass(e.find(".m-login__signin")[0], "flipInX animated")
  });
}
